<template>
   <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
       <div class="van-toast__text">success</div>
   </div>
    <div class="top-nav" style="background: #fff !important;">
       <p class="promotion-text"><b>Agency</b></p>
         <svg class="svg-icon icon-subordinate" @click="$router.push('NewSubordinate')" style="width: 30px !important; margin-bottom: -50px; margin-left: 25%;">
            <use xlink:href="#icon-subordinate"></use>
         </svg>
    </div>
    <div class="container-fluid" style="margin-top: 0px;">
       <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
             <div data-v-6cf5705a="" data-v-600663f7="" class="container" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
                <div data-v-6cf5705a="" class="amount">{{ Number(userdetails.total_commission).toFixed(2) }}</div>
                <div data-v-6cf5705a="" class="amount_txt">Yesterday's total commission</div>
                <div data-v-6cf5705a="" class="tip">Upgrade the level to increase commission income</div>
                <div data-v-6cf5705a="" class="info_content">
                   <div data-v-6cf5705a="" class="info">
                      <div data-v-6cf5705a="" class="head">
                         <svg data-v-6cf5705a="" class="svg-icon icon-directSubordinates">
                            <use xlink:href="#icon-directSubordinates"></use>
                         </svg>
                         Direct subordinates
                      </div>
                      <div data-v-6cf5705a="" class="line1 r" v-if="userdetails.direct_user !== null">
                         <div data-v-6cf5705a="">{{ userdetails.direct_user }}</div>
                         number of register
                      </div>
                      <div data-v-6cf5705a="" class="line1 r" v-else>
                         <div data-v-6cf5705a="">0</div>
                         number of register
                      </div>
                      <div data-v-6cf5705a="" class="line2 r" v-if="userdetails.recharge_total === null">
                         <div data-v-6cf5705a="">0</div>
                         Deposit number
                      </div>
                      <div data-v-6cf5705a="" class="line2 r" v-else>
                         <div data-v-6cf5705a="">{{ userdetails.recharge_total }}</div>
                         Deposit number
                      </div>

                      <div data-v-6cf5705a="" class="line3 r" v-if="userdetails.total_level1 === null">
                         <div data-v-6cf5705a="">0</div>
                         Deposit amount
                      </div>
                      <div data-v-6cf5705a="" class="line3 r" v-else>
                         <div data-v-6cf5705a="">{{ userdetails.total_level1 }}</div>
                         Deposit amount
                      </div>
                      <div data-v-6cf5705a="" class="line1 r">
                         <div data-v-6cf5705a="" v-if="userdetails.first_deposit !== null">{{ userdetails.first_deposit }}</div>
                         <div data-v-6cf5705a="" v-else>0</div>
                         Number of people making first deposit
                      </div>
                   </div>
                   <div data-v-6cf5705a="" class="info">
                      <div data-v-6cf5705a="" class="head u2">
                         <svg data-v-6cf5705a="" class="svg-icon icon-teamSubordinates">
                            <use xlink:href="#icon-teamSubordinates"></use>
                         </svg>
                         Team subordinates
                      </div>
                      <div data-v-6cf5705a="" class="line1">
                         <div data-v-6cf5705a="" v-if="userdetails.level7_total === null">0</div>
                         <div data-v-6cf5705a="" v-else>{{ userdetails.level7_total }}</div>
                         number of register
                      </div>
                      <div data-v-6cf5705a="" class="line2">
                         <div data-v-6cf5705a="">{{ userdetails.total_level }}</div>
                         Deposit number
                      </div>
                      <div data-v-6cf5705a="" class="line3">
                         <div data-v-6cf5705a="">{{ userdetails.total_deposit_level7 }}</div>
                         Deposit amount
                      </div>
                      <div data-v-6cf5705a="" class="line1">
                         <div data-v-6cf5705a="">{{ userdetails.total_first_deposit }}</div>
                         Number of people making first deposit
                      </div>
                   </div>
                </div>
             </div>
             <div data-v-600663f7="" class="content" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
                <div data-v-600663f7="" class="shareBtnContainer"><button data-v-600663f7="" class="shareBtn">INVITATION LINK</button></div>
                <div data-v-600663f7="" class="promote__cell">
                   <div data-v-600663f7="" class="promote__cell-item">
                      <div data-v-600663f7="" class="label">
                         <svg data-v-600663f7="" class="svg-icon icon-copy_Code">
                            <use xlink:href="#icon-copy_Code"></use>
                         </svg>
                         <span data-v-600663f7="">Copy invitation code</span>
                      </div>
                      <div data-v-600663f7="" class="arrow">
                         <span data-v-600663f7="" id="link">
                            {{ userdetails.usercode }} 
                            </span>
                            <span>
                            <svg data-v-600663f7="" @click="copylink()" class="svg-icon icon-copy" >
                               <use xlink:href="#icon-copy"></use>
                            </svg>
                         </span>
                      </div>
                   </div>
                   <div data-v-600663f7="" class="promote__cell-item">
                      <div data-v-600663f7="" class="label">
                         <svg data-v-600663f7="" class="svg-icon icon-copy_Code">
                            <use xlink:href="#icon-copy_Code"></use>
                         </svg>
                         <span data-v-600663f7="">Copy invitation link</span>
                      </div>
                      <div data-v-600663f7="" class="arrow">
                         <span data-v-600663f7="" id="link1" style="display:none;">
                           https://jackpotclub.pro/register?r_code={{ userdetails.usercode }}
                            </span>
                            <span data-v-600663f7="">
                              https://royal60..
                            <svg data-v-600663f7="" @click="copylink1()" data-clipboard-text="https://manager.jackpotclub.pro//#/register?r_code={{userdetails.usercode}}" class="svg-icon icon-copy" >
                               <use xlink:href="#icon-copy"></use>
                            </svg>
                         </span>
                      </div>
                   </div>
                   <div data-v-600663f7="" class="promote__cell-item" @click="$router.push('TeamReport')">
                      <div data-v-600663f7="" class="label">
                         <svg data-v-600663f7="" class="svg-icon icon-team_port">
                            <use xlink:href="#icon-team_port"></use>
                         </svg>
                         <span data-v-600663f7="">Subordinate data</span>
                      </div>
                      <div data-v-600663f7="" class="arrow">
                         <i data-v-600663f7="" class="van-badge__wrapper van-icon van-icon-arrow" style="color: var(--text_color_L1); font-size: 24px;">
                            <!----><!----><!---->
                         </i>
                      </div>
                   </div>
                   <!-- <div data-v-600663f7="" class="promote__cell-item">
                      <div data-v-600663f7="" class="label">
                         <svg data-v-600663f7="" class="svg-icon icon-commission">
                            <use xlink:href="#icon-commission"></use>
                         </svg>
                         <span data-v-600663f7="">Commission detail</span>
                      </div>
                      <div data-v-600663f7="" class="arrow">
                         <i data-v-600663f7="" class="van-badge__wrapper van-icon van-icon-arrow" style="color: var(--text_color_L1); font-size: 24px;">
                            
                         </i>
                      </div>
                   </div> -->
                   <!-- <div data-v-600663f7="" class="promote__cell-item">
                      <div data-v-600663f7="" class="label">
                         <svg data-v-600663f7="" class="svg-icon icon-invite_reg">
                            <use xlink:href="#icon-invite_reg"></use>
                         </svg>
                         <span data-v-600663f7="">Invitation rules</span>
                      </div>
                      <div data-v-600663f7="" class="arrow">
                         <i data-v-600663f7="" class="van-badge__wrapper van-icon van-icon-arrow" style="color: var(--text_color_L1); font-size: 24px;">
                            
                         </i>
                      </div>
                   </div> -->
                   <div data-v-600663f7="" class="promote__cell-item">
                      <div data-v-600663f7="" class="label" @click="$router.push('CustomerService')">
                         <svg data-v-600663f7="" class="svg-icon icon-server">
                            <use xlink:href="#icon-server"></use>
                         </svg>
                         <span data-v-600663f7="">Agent line customer service</span>
                      </div>
                      <div data-v-600663f7="" class="arrow">
                         <i data-v-600663f7="" class="van-badge__wrapper van-icon van-icon-arrow" style="color: var(--text_color_L1); font-size: 24px;">
                            <!----><!----><!---->
                         </i>
                      </div>
                   </div>
                </div>
                <div data-v-600663f7="" class="commission">
                   <div data-v-600663f7="" class="commission__title">
                      <svg data-v-600663f7="" class="svg-icon icon-promotionData">
                         <use xlink:href="#icon-promotionData"></use>
                      </svg>
                      <span data-v-600663f7="">promotion data</span>
                   </div>
                   <div data-v-600663f7="" class="commission__body">
                      <div data-v-600663f7=""><span data-v-600663f7="">{{ Number(userdetails.week_commission).toFixed(2) }}</span><span data-v-600663f7="">This Week</span></div>
                      <!--因越南代理需求，临时处理下这个问题，周一再统一处理--><span data-v-600663f7=""></span>
                      <div data-v-600663f7=""><span data-v-600663f7="">{{ Number(userdetails.all_commission).toFixed(2) }}</span><span data-v-600663f7="">Total commission</span></div>
                   </div>
                   <div data-v-600663f7="" class="commission__body">
                      <div data-v-600663f7=""><span data-v-600663f7="">{{ userdetails.all_direct_user }}</span><span data-v-600663f7="">direct subordinate</span></div>
                      <!--因越南代理需求，临时处理下这个问题，周一再统一处理--><span data-v-600663f7=""></span>
                      <div data-v-600663f7=""><span data-v-600663f7="">{{ userdetails.all_level7_total }}</span><span data-v-600663f7="">Total number of subordinates in the team</span></div>
                      
                   </div>
                </div>
                <!-- <PromoRank /> -->
             </div>
          </div>
          <div class="col-md-4"></div>
       </div>
    </div>
    <!-- footer-nav -->
    <div class="footer-nav" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div class="footer-nav-item" @click="$router.push('home')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-home">
             <use xlink:href="#icon-home"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Home</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('activity')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
             <use xlink:href="#icon-activity"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Activity</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item active" @click="$router.push('promotion')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
             <use xlink:href="#icon-promotion"></use>
          </svg>
          <div data-v-6ab3f23e="" class="promotionBg"></div>
          <span data-v-6ab3f23e="">Promotion</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('wallet')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
             <use xlink:href="#icon-wallet"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Wallet</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('Mine')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-main">
             <use xlink:href="#icon-main"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Account</span>
       </div>
    </div>
 </template>
 <script>
 import axios from 'axios';
    export default {
        name: "PromotionView",
        data() {
       return {
           userdetails: {
               username: null,
               balance: null,
               usercode: null,
               commision: "0",
               total_commission: "0",
               week_commission: "0",
               all_commission: "0",
               intrest: "0",
               notice: "Welcome",
               total_level1: null,
               first_deposit: null,
               direct_user: null,
               recharge_total: null,
               total_first_deposit: 0,
               total_deposit_level7: 0,
               total_level: 0,
               level7_total: 0,
               all_level7_total: 0,
               all_direct_user: 0,

           },
           user: [],
           dailysign:[]
            }
      },
      beforeCreate: function () {
        if (localStorage.getItem('username') == null)
            this.$router.push('Login');
      },
      created: function () {
       this.getUserdetails();
      },
      methods: {
         pop(message) {
         document.getElementById("snackbar").innerHTML = message;
         document.getElementById("snackbar").style.display = "";
         setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1000);
       },
       getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.jackpotclub.pro/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.usercode = this.Users[0].usercode;
               this.userdetails.balance = this.Users[0].balance;
               this.userdetails.userid = this.Users[0].user_id;
               this.userdetails.name = this.Users[0].name;
               this.userdetails.logindate = this.Users[0].last_login_date;
               this.userdetails.notice = this.Users[1].notice;
               this.userdetails.commision = this.Users[2].bonus;
               this.userdetails.days=this.Users[3].days;
               this.userdetails.status=this.Users[4].status;
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=yes_commission&username=' + this.userdetails.username).then((res) => {
               this.Commision = res.data.user_Data;
               console.log(this.Commision);
               this.userdetails.total_commission = this.Commision[0].total_commission;
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=all_commission&username=' + this.userdetails.username).then((res) => {
               this.AllCommision = res.data.user_Data;
               console.log(this.AllCommision);
               this.userdetails.all_commission = this.AllCommision[0].all_commission;
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=week_commission&username=' + this.userdetails.username).then((res) => {
               this.WeekCommision = res.data.user_Data;
               console.log(this.WeekCommision);
               this.userdetails.week_commission = this.WeekCommision[0].week_commission;
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=direct&username=' + this.userdetails.username).then((res) => {
               this.Direct = res.data.user_Data;
               console.log(this.Direct);
               this.userdetails.direct_user = this.Direct[0].total;
           }).catch((err) => {
               console.log(err);
           })

           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=direct_all&username=' + this.userdetails.username).then((res) => {
               this.AllDirect = res.data.user_Data;
               console.log(this.AllDirect);
               this.userdetails.all_direct_user = this.AllDirect[0].total;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=deposit_number&username=' + this.userdetails.username).then((res) => {
               this.Recharge = res.data.user_Data;
               console.log(this.Recharge);
               this.userdetails.recharge_total = this.Recharge[0].recharge_count;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=level1_deposit&username=' + this.userdetails.username).then((res) => {
               this.Level1amount = res.data.user_Data;
               console.log(this.Level1amount);
               this.userdetails.total_level1 = this.Level1amount[0].recharge_amount;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=first_deposit&username=' + this.userdetails.username).then((res) => {
               this.Firstdeposit = res.data.user_Data;
               console.log(this.Firstdeposit);
               this.userdetails.first_deposit = this.Firstdeposit[0].first_deposit;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=level7_users&username=' + this.userdetails.username).then((res) => {
               this.TotalUserlvl7 = res.data.user_Data;
               console.log(this.TotalUserlvl7);
               this.userdetails.level7_total = this.TotalUserlvl7[0].team_subordinate;
               
               
           }).catch((err) => {
               console.log(err);
           })

           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=all_level7_users&username=' + this.userdetails.username).then((res) => {
               this.AllTotalUserlvl7 = res.data.user_Data;
               console.log(this.AllTotalUserlvl7);
               this.userdetails.all_level7_total = this.AllTotalUserlvl7[0].level7_total;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=level7_recharge_count&username=' + this.userdetails.username).then((res) => {
               this.TotalLevel = res.data.user_Data;
               console.log(this.TotalLevel);
               this.userdetails.total_level = this.TotalLevel.total_level;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=level7_total_recharge&username=' + this.userdetails.username).then((res) => {
               this.TotalLevelDeposit = res.data.user_Data;
               console.log(this.TotalLevelDeposit);
               this.userdetails.total_deposit_level7 = this.TotalLevelDeposit.total_level_deposit;
               
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/promotion_api_tcgames.php?action=level7_first_deposit&username=' + this.userdetails.username).then((res) => {
               this.FirstDepositTotal = res.data.user_Data;
               console.log(this.FirstDepositTotal);
               this.userdetails.total_first_deposit = this.FirstDepositTotal.total_level_first_deposit;
               
               
           }).catch((err) => {
               console.log(err);
           })
       },
       copylink() {
            this.pop("Copied");
            var inputc = document.body.appendChild(document.createElement("input"));
            inputc.value = document.getElementById("link").innerHTML;
            // inputc.focus();
            inputc.select();
            document.execCommand('copy');
            inputc.parentNode.removeChild(inputc);
        },
       copylink1() {
            this.pop("Copied");
            var inputc = document.body.appendChild(document.createElement("input"));
            inputc.value = document.getElementById("link1").innerHTML;
            // inputc.focus();
            inputc.select();
            document.execCommand('copy');
            inputc.parentNode.removeChild(inputc);
        },
       }
    }
    
 </script>